<template>
  <v-dialog
    v-model="showModal"
    max-width="800"
    scrollable
  >
    <div>
      <v-card
        color="#ECEFF1"
        shaped
      >
        <br>
        <br>
        <h2 class="title-style">
          <v-icon class="icon-style">
            mdi-pencil
          </v-icon> Edit Ticker
        </h2>
        <v-card-text class="text-body-1 text-center">
          <v-container>
            <v-col cols="12">
              <v-text-field
                v-model="title"
                label="Ticker Title*"
                required
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
            >
              <v-textarea
                v-model="comment"
                label="Comment"
                filled
              />
            </v-col>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Start date"
                >
                  <v-datetime-picker
                    ref="start"
                    v-model="start"
                    label="Start Date time*"
                    :text-field-props="{
                      suffix: userTimeZone,
                      errorMessages: errors,
                      class: 'custom-label-color',
                    }"
                    :date-picker-props="{
                      headerColor: '#37474F',
                      min: minStartDate
                    }"
                    :time-picker-props="{
                      headerColor: '#37474F'
                    }"
                  >
                    <template v-slot:dateIcon>
                      <v-icon
                        large
                        color="#37474F"
                      >
                        mdi-calendar
                      </v-icon>
                    </template>
                    <template v-slot:timeIcon>
                      <v-icon
                        color="#37474F"
                        large
                      >
                        mdi-alarm
                      </v-icon>
                    </template>
                    <template v-slot:actions>
                      <v-btn
                        color="grey lighten-1"
                        text
                        @click.native="cancelStartDialog"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="submitStartDialog"
                      >
                        Ok
                      </v-btn>
                    </template>
                  </v-datetime-picker>
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-datetime-picker
                  ref="end"
                  v-model="end"
                  label="End Date time"
                  :text-field-props="{
                    hint: 'Leave blank if never ends',
                    persistentHint: true,
                    suffix: userTimeZone
                  }"
                  :date-picker-props="{
                    headerColor: '#37474F',
                    min: minEndDate
                  }"
                  :time-picker-props="{
                    headerColor: '#37474F'
                  }"
                >
                  <template v-slot:dateIcon>
                    <v-icon
                      color="#37474F"
                      large
                    >
                      mdi-calendar
                    </v-icon>
                  </template>
                  <template v-slot:timeIcon>
                    <v-icon
                      color="#37474F"
                      large
                    >
                      mdi-alarm
                    </v-icon>
                  </template>
                  <template v-slot:actions>
                    <v-btn
                      color="red darken-1"
                      text
                      @click.native="clearEndDialog"
                    >
                      Clear
                    </v-btn>
                    <v-btn
                      color="grey lighten-1"
                      text
                      @click.native="cancelEndDialog"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="submitEndDialog"
                    >
                      Ok
                    </v-btn>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-col
                cols="12"
                class="text-left"
              >
                <v-btn
                  v-if="showScheduleBtn"
                  small
                  dark
                  class="mt-2"
                  color="#FF3700"
                  depressed
                  default
                  rounded
                  @click="showAdvancedSchedule = true"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                  Advanced Schedule
                </v-btn>
                <advanced-scheduling v-model="showAdvancedSchedule" />
              </v-col>
              <v-col
                cols="12"
              >
                <advanced-scheduling-list @show-content-form="showContentForm" />
                <br>
                <advanced-schedule-edit-before-creation
                  v-if="showScheduleContent"
                  :editvalues="editScheduleContent"
                  :scheduleindex="scheduleIndex"
                  @closed="showScheduleContent = false"
                />
                <br>
                <advanced-schedule-list-for-edit
                  @show-form="showFormForEdit"
                  @update-content="fetchLatestTickerList"
                />
                <advanced-schedule-form-for-edit
                  v-if="showEdit"
                  :editvalues="editScheduleInfo"
                  @closed="showEdit = false"
                  @get-latest="fetchAdvancedScheduleForEdit"
                  @update-content="fetchLatestTickerList"
                />
              </v-col>
            </v-row>
          </v-container>
          <p class="text-sm-left">
            *Indicates required field
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-2"
            color="red darken-3"
            @click="cancel()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            class="mt-2"
            color="#FF3700"
            @click="next()"
          >
            Next
          </v-btn>
        </v-card-actions>
      </v-card>
      <centre-spinner
        :loading="loading"
      />
    </div>
  </v-dialog>
</template>
<script>
  import Constants from 'src/constants';
  import moment from 'moment-timezone';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import AdvancedScheduling from 'src/views/dashboard/component/schedules/AdvancedScheduling.vue';
  import AdvancedSchedulingList from 'src/views/dashboard/component/schedules/AdvancedSchedulingList.vue';
  import AdvancedScheduleListForEdit from 'src/views/dashboard/component/schedules/AdvancedScheduleListForEdit.vue';
  import AdvancedScheduleFormForEdit from 'src/views/dashboard/component/schedules/AdvancedScheduleFormForEdit.vue';
  import AdvancedScheduleEditBeforeCreation from 'src/views/dashboard/component/schedules/AdvancedScheduleEditBeforeCreation.vue';
  import ModuleHelper from 'src/helpers/module-helper';
  import global from 'src/mixins/global';

export default {
    name: 'TickerUpdate',
    components: {
      'centre-spinner': spinner,
      AdvancedScheduling,
      AdvancedSchedulingList,
      AdvancedScheduleListForEdit,
      AdvancedScheduleFormForEdit,
      AdvancedScheduleEditBeforeCreation,
    },
    mixins: [global],
    props: {
      tickerValues: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        showModal: true,
        title: '',
        start: '',
        end: null,
        comment: '',
        minStartDate: moment().format('YYYY-MM-DD'),
        loading: false,
        showAdvancedSchedule: false,
        editScheduleInfo: {},
        editScheduleContent: {},
        showEdit: false,
        showScheduleContent: false,
        scheduleIndex: 0,
        tickerFormDetails: {
            tickerTitle: '',
            comment: '',
            start: '',
            end: '',
        },
        helper: new ModuleHelper(),
      };
    },
    computed: {
      minEndDate () {
        return moment(this.start).format('YYYY-MM-DD');
      },
       userTimeZone () {
        var Zone = moment.tz.guess();
        var timezone = moment.tz(Zone).zoneAbbr();
        return timezone;
      },
       advancedScheduleData () {
        return this.$store.getters['schedule/getScheduleData'];
      },
      advancedScheduleDataForEdit () {
        return this.$store.getters['schedule/getAdvancedScheduleForEdit'];
      },
      showScheduleBtn () {
        return (this.helper.isSubModuleExistUnderSubModule(Constants.MODULE_WIDGETS, Constants.SUB_MODULE_TICKER, Constants.SUB_MODULE_ADVANCED_SCHEDULING) && this.permissionCheck('create-weekly-schedule'));
      },
    },
     watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      this.loading = true;
      this.title = this.tickerValues.title;
      this.comment = this.tickerValues.comment;
      this.start = this.convertStringToLocalDatetime(this.tickerValues.start);
      this.end = this.tickerValues.end ? this.convertStringToLocalDatetime(this.tickerValues.end) : null;
      this.$store.dispatch('schedule/clearAdvancedScheduleForEdit');
      this.$store.dispatch('schedule/clearAdvancedSchedule');
      this.permissionCheck('find-weekly-schedule') && await this.fetchAdvancedScheduleForEdit();
      this.loading = false;
    },
    methods: {
      convertStringToLocalDatetime (stringDatetime) {
        return moment(stringDatetime).local().toDate();
      },
      async fetchAdvancedScheduleForEdit () {
        if (this.tickerValues.schedules.length > 0) {
          const schedules = [];
          for (const advancedSchedule of this.tickerValues.schedules) {
            schedules.push(advancedSchedule.id);
          }
          for (var i = 0; i < this.tickerValues.schedules.length; i++) {
            await this.$store.dispatch('schedule/fetchAdvancedScheduleForEdit', schedules[i]);
          }
        }
      },
      cancel () {
        this.$emit('closed');
        this.$store.dispatch('schedule/clearAdvancedSchedule');
        this.$store.dispatch('schedule/clearAdvancedScheduleForEdit');
      },
      showFormForEdit (val) {
        this.editScheduleInfo = this.advancedScheduleDataForEdit.find(
          schedule => schedule.id === val,
        );
        this.showEdit = true;
      },
      showContentForm (val) {
        this.scheduleIndex = val.index;
        this.editScheduleContent = this.advancedScheduleData.find(
          schedule => schedule.id === val.scheduleId,
        );
        this.showScheduleContent = true;
      },
      cancelStartDialog () {
        this.$refs.start.resetPicker();
        this.$refs.start.init();
      },
      submitStartDialog () {
        this.$refs.start.okHandler();
      },
      cancelEndDialog () {
        this.$refs.end.resetPicker();
        this.$refs.end.init();
      },
      submitEndDialog () {
        this.$refs.end.okHandler();
      },
      clearEndDialog () {
        this.$refs.end.clearHandler();
      },
      next () {
        if (this.start === '' || this.title === '') {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please enter the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        this.tickerFormDetails.tickerTitle = this.title;
        this.tickerFormDetails.comment = this.comment;
        this.tickerFormDetails.start = this.start;
        this.tickerFormDetails.end = this.end;
        setTimeout(() => {
          this.loading = false;
          this.$emit('success', this.tickerFormDetails);
        }, 2000);
      },
      fetchLatestTickerList () {
        this.$emit('fetch-latest-ticker');
      },
    },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
</style>

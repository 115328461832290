<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-cogs
      </v-icon> Tickers
    </h2>
    <v-col
      cols="12"
      class="text-right"
    >
      <v-btn
        v-if="permissionCheck('create-ticker')"
        color="#FF3700"
        @click="newTicker()"
      >
        create ticker
      </v-btn>
    </v-col>
    <v-tabs
      v-model="tab"
      color="#FF3700"
      class="tabs-styling"
      background-color="#ECEFF1"
    >
      <v-tab
        v-for="item in items"
        :key="item.tab"
      >
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      class="my-tabs-background-style"
      touchless
    >
      <v-tab-item
        v-for="item in items"
        :key="item.tab"
      >
        <v-card-text>
          <component :is="item.content" />
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
  import TickerSettings from './TickerSettings';
  import TickerList from './TickerList';
  import global from 'src/mixins/global';
  import OrganizationTickerSettings from './OrganizationTickerSettings';

  export default {
    name: 'Tickers',

    components: {
      'ticker-settings': TickerSettings,
      'ticker-list': TickerList,
      'organization-ticker-settings': OrganizationTickerSettings,
    },
    mixins: [global],
    data: () => ({
      tab: null,
      items: [
        { tab: 'Ticker Settings', content: TickerSettings },
        { tab: 'Default Setting', content: OrganizationTickerSettings },
        { tab: 'Tickers List', content: TickerList },
      ],
    }),
    methods: {
      newTicker () {
        this.$router.push({ name: 'Create Ticker' });
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-tabs-background-style {
    background-color: #ECEFF1 !important;
}
.tabs-styling {
    margin-left: 20px;
}
</style>

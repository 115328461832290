<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
    class="my-container-style"
  >
    <delete-ticker
      v-if="showDelete"
      :title="'Delete Ticker?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
    <edit-ticker
      v-if="showEdit"
      :ticker-values="editTickerInfo"
      @closed="showEdit = false"
      @success="showDevice"
      @fetch-latest-ticker="getTickersList()"
    />
    <edit-ticker-linked-boards
      v-if="showDigiBoardEdit"
      :ticker-values="editTickerInfo"
      :editvalues="tickerFormDetails"
      @closed="showDigiBoardEdit = false"
      @update-success="getTickersList()"
    />
    <v-alert
      v-if="!permissionCheck('list-all-tickers')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-toolbar
        flat
        color="#ECEFF1"
      >
        <v-toolbar-title class="my-toolbar-style">
          Ticker  List
        </v-toolbar-title>
      </v-toolbar>
      <v-data-table
        :headers="TickerTable.headers"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="100"
        :items="tickers"
        item-key="id"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        show-expand
        disable-sort
      >
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr>
            <td>{{ item.title }}</td>
            <td>{{ item.start | convertStringToLocalDatetime }}</td>
            <td><span v-if="item.end !== null">{{ item.end | convertStringToLocalDatetime }}</span> <span v-else>No End Date</span></td>
            <td>
              <font
                :color="getColor(item.active)"
                class="font-my-style"
              >
                {{ item.active | statusUpdate }}
              </font>
            </td>
            <td>{{ item.updated_at | diffHumans }}</td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                color="#37474F"
                x-small
                icon
                @click="editTicker(item.id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#C62828"
                @click="deleteTicker(item.id, item.boards)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                icon
                small
                @click.stop
                @click="expand(!isExpanded)"
              >
                <v-icon
                  v-if="!isExpanded"
                  @click="getBoardDetails(item.boards)"
                >
                  mdi-arrow-down-drop-circle
                </v-icon>
                <v-icon v-if="isExpanded">
                  mdi-arrow-up-drop-circle
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
        <template
          v-slot:expanded-item="{ headers, item }"
        >
          <td
            :colspan="headers.length"
          >
            <v-row>
              <v-col
                v-if="showAdvancedSchedule(item.schedules)"
                cols="12"
                lg="4"
              >
                <v-card>
                  <v-toolbar
                    color="#37474F"
                    dark
                  >
                    <v-toolbar-title>Advanced Schedule</v-toolbar-title>
                  </v-toolbar>
                  <v-list>
                    <v-list-item-group>
                      <template v-for="(schedule, index) in item.schedules">
                        <v-list-item :key="'item'+ index">
                          <template>
                            <v-list-item-content class="ml-1">
                              <v-list-item-title>
                                <strong>
                                  Start: {{ schedule.start | twelveHour }} End: {{ schedule.end | twelveHour }}
                                </strong>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <strong>
                                  Active Days: {{ schedule.active_days | arrangePretty }}
                                </strong>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                        <v-divider
                          v-if="index < item.schedules.length - 1"
                          :key="index"
                        />
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col
                v-if="showTickerComment(item.comment)"
                cols="12"
                lg="4"
              >
                <v-card>
                  <v-toolbar
                    color="#37474F"
                    dark
                  >
                    <v-toolbar-title>Ticker Comment</v-toolbar-title>
                  </v-toolbar>
                  <p class="comment-style">
                    {{ item.comment }}
                  </p>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                lg="4"
              >
                <v-card>
                  <v-toolbar
                    color="#37474F"
                    dark
                  >
                    <v-toolbar-title>Device Details</v-toolbar-title>
                  </v-toolbar>
                  <v-list>
                    <v-list-item-group>
                      <template v-for="(device, index) in boardDetails ">
                        <v-list-item :key="'item'+ index">
                          <template>
                            <v-list-item-content class="ml-1">
                              <v-list-item-title>
                                <strong>
                                  Device Id: {{ device.external_id }}
                                </strong>
                              </v-list-item-title>
                              <br>
                              <v-list-item-subtitle>
                                <strong>
                                  Device Location: {{ device.location }}
                                </strong>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                        <v-divider
                          v-if="index < boardDetails.length - 1"
                          :key="index"
                        />
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import global from 'src/mixins/global';
  import moment from 'moment';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import EditTicker from './EditTicker.vue';
  import EditTickerLinkedBoards from './EditTickerLinkedBoards.vue';
  import Constants from 'src/constants';

  export default {
    name: 'TickerList',
    components: {
      'centre-spinner': spinner,
      'delete-ticker': DeleteDialog,
      'edit-ticker': EditTicker,
      'edit-ticker-linked-boards': EditTickerLinkedBoards,
    },
    filters: {
      convertStringToLocalDatetime (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      diffHumans (val) {
        return moment(val).fromNow();
      },
      arrangePretty (val) {
        return val.join(', ');
      },
      twelveHour (val) {
        return moment(val, 'HH:mm').format('hh:mm a');
      },
      statusUpdate (active) {
        if (active === true) return 'Active*';
        else return 'Inactive';
      },
    },
    mixins: [global],
    data () {
      return {
        search: '',
        TickerTable: {
          headers: [
          { text: 'Title', align: 'start', value: 'title' },
          { text: 'Start', value: 'start' },
          { text: 'End', value: 'end' },
          { text: 'Status', value: 'active' },
          { text: 'Last Updated', value: 'updated_at' },
          { text: 'Edit', value: 'more' },
          { text: 'Delete', value: 'delete' },
          { text: 'More', value: 'data-table-expand' },
        ],
        },
        loading: false,
        expanded: [],
        singleExpand: true,
        showDelete: false,
        deleteId: '',
        boardExternalIds: [],
        editTickerInfo: {},
        showEdit: false,
        tickerFormDetails: {},
        showDigiBoardEdit: false,
        boardDetails: [],
      };
    },
    computed: {
      tickers () {
        const list = this.$store.getters['widgets/getTickerList'];
        const sortedList = list.sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
        });
        return sortedList;
      },
       digiBoards () {
        return this.$store.getters['digiboards/getDigiBoards'];
      },
    },
    async mounted () {
      this.permissionCheck('list-all-tickers') && await this.getTickersList();
      this.permissionCheck('list-all-tickers') && this.fetchDigiBoards();
    },
    methods: {
      async getTickersList () {
        this.loading = true;
        await this.$store.dispatch('widgets/fetchTickersList');
        this.loading = false;
      },
      showAdvancedSchedule (schedule) {
        if (schedule.length > 0) {
          return true;
        }
        return false;
      },
      deleteTicker (tickerId, boardExternalIds) {
        if (this.permissionCheck('delete-ticker') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showDelete = true;
        this.deleteId = tickerId;
        this.boardExternalIds = boardExternalIds;
      },
      deleteConfirmed () {
        this.loading = true;
        this.$store.dispatch('publish/addSelectedBoardsForPublish', this.boardExternalIds);
        this.$store.dispatch('widgets/deleteTicker', this.deleteId)
          .then(response => {
            this.$store.dispatch('alert/onAlert', {
              message: 'Ticker deleted.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.getTickersList();
          }).finally(
            this.showDelete = false,
            this.deleteId = '',
            this.loading = false,
          );
      },
      editTicker (tickerId) {
        if (this.permissionCheck('update-ticker') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.editTickerInfo = this.tickers.find(
          ticker => ticker.id === tickerId,
        );
        this.showEdit = true;
      },
      showDevice (val) {
        this.tickerFormDetails = val;
        this.showEdit = false;
        this.showDigiBoardEdit = true;
      },
      fetchDigiBoards () {
        this.$store.dispatch('digiboards/fetchDigiBoards');
      },
      getBoardDetails (boardDetails) {
        const filteredBoards = this.digiBoards.filter(item => boardDetails.includes(item.external_id));
        this.boardDetails = filteredBoards;
      },
      getColor (active) {
        if (active === true) return '#2E7D32';
        else return '#C62828';
      },
      showTickerComment (comment) {
        if (comment !== null) {
          return true;
        }
        return false;
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.my-toolbar-style {
  color: #37474F;
  font-weight: bold;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.my-container-style {
  padding: 0px;
}
.comment-style {
  padding-left: 16px;
  font-size: 14px;
  padding-bottom: 12px;
  padding-top: 12px;
  margin-bottom: 0px;
}
.font-my-style {
  font-weight: bold;
}
</style>

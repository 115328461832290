<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
    class="my-container-style"
  >
    <div>
      <apply-ticker-settings
        v-if="showPreviewForm"
        :ticker-settings-details="tickerSettingsDetails"
        @closed="showPreviewForm = false"
      />
    </div>
    <v-alert
      v-if="!permissionCheck('list-all-ticker-settings')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-toolbar
        flat
        color="#ECEFF1"
      >
        <v-toolbar-title class="my-toolbar-style">
          Ticker Settings List
        </v-toolbar-title>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="tickerSettings"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <span v-if="item.name !== null">
                {{ item.name }}
              </span>
              <span v-else>
                N/A
              </span>
            </td>
            <td>
              <v-icon
                size="30"
                :color="item.bg_color"
              >
                mdi-rectangle
              </v-icon>
            </td>
            <td>{{ item.position }}</td>
            <td>{{ item.title }}</td>
            <td>
              {{ item.direction }}
            </td>
            <td>
              {{ item.speed }}s
            </td>
            <td>
              <v-icon
                size="30"
                :color="item.text_color"
              >
                mdi-rectangle
              </v-icon>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="previewSettings(item.id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import global from 'src/mixins/global';
  import ApplyTickerSettings from './ApplyTickerSettings';
  import Constants from 'src/constants';

  export default {
    name: 'TickerSettings',
    components: {
      'centre-spinner': spinner,
      'apply-ticker-settings': ApplyTickerSettings,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },
    mixins: [global],
    data () {
      return {
        search: '',
        headers: [
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Bg Color', value: 'bg_color' },
          { text: 'Ticker Position', value: 'position' },
          { text: 'Title Position', value: 'title' },
          { text: 'Direction', value: 'direction' },
          { text: 'Speed', value: 'speed' },
          { text: 'Text Color', value: 'text_color' },
          { text: 'Preview', value: 'preview' },
        ],
        loading: false,
        showPreviewForm: false,
        tickerSettingsDetails: {},
      };
    },
    computed: {
      tickerSettings () {
        return this.$store.getters['widgets/getTickerSettingsList'];
      },
    },
    async mounted () {
     this.permissionCheck('list-all-ticker-settings') && await this.fetchTickerSettingsList();
    },
    methods: {
      async fetchTickerSettingsList () {
        this.loading = true;
        await this.$store.dispatch('widgets/fetchTickerSettingsList');
        this.loading = false;
      },
      previewSettings (settingsId) {
        if (this.permissionCheck('apply-ticker-settings') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.tickerSettingsDetails = this.tickerSettings.find(
          settings => settings.id === settingsId,
        );
        this.showPreviewForm = true;
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.my-toolbar-style {
  color: #37474F;
  font-weight: bold;
}
.my-container-style {
  padding: 0px;
}
</style>
